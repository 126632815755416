import { ChainId, Token, WBNB } from '@pancakeswap/sdk'
import { BUSD_TESTNET, CAKE_TESTNET, BNB_TESTNET } from './common'

export const bscTestnetTokens = {
  wbnb: WBNB[ChainId.BSC_TESTNET],
  cake: CAKE_TESTNET,
  busd: BUSD_TESTNET,
  bnb: BNB_TESTNET,
  syrup: new Token(
    ChainId.BSC_TESTNET,
    '0x83c693e38a0bb6BCC88922E01D0094D6E75635c3',
    // '0xfE1e507CeB712BDe086f3579d2c03248b2dB77f9',
    18,
    'SYRUP',
    'SyrupBar Token',
    'https://pancakeswap.finance/',
  ),
  bake: new Token(
    ChainId.BSC_TESTNET,
    '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    18,
    'BAKE',
    'Bakeryswap Token',
    'https://www.bakeryswap.org/',
  ),
  acx: new Token(ChainId.BSC_TESTNET, '0x647e792df956E678B99934215ABc7D2D658A4e2A', 18, 'ACX', 'ACX Token'),
  tn: new Token(ChainId.BSC_TESTNET, '0x5a4820D07089bC0b5c014a47E694106026EF343E', 18, 'TN', 'TN Token'),
  busdcoin: new Token(ChainId.BSC_TESTNET, '0x14c808E3385E03747d0B6bA58Ac2DA56D1961169', 18, 'BUSD', 'BUSD Token'),
  tncoin: new Token(ChainId.BSC_TESTNET, '0xE5041590B5B5F3c24E6587401bD988Ae609a3a71', 18, 'TN', 'TN Token'),
  hbtc: new Token(ChainId.BSC_TESTNET, '0x3Fb6a6C06c7486BD194BB99a078B89B9ECaF4c82', 18, 'HBTC', 'Huobi BTC'),
  wbtc: new Token(ChainId.BSC_TESTNET, '0xfC8bFbe9644e1BC836b8821660593e7de711e564', 8, 'WBTC', 'Wrapped BTC'),
}

import { ChainId, Token, WETH9 } from '@pancakeswap/sdk'
import { USDC_GOERLI, CAKE_GOERLI } from './common'

export const goerliTestnetTokens = {
  weth: WETH9[ChainId.GOERLI],
  acx: new Token(ChainId.GOERLI, '0x7806f4BCb9ebBFf358dff89688B82ECa92FEF859', 18, 'ACX', 'AcxToken', ''),
  tn: new Token(ChainId.GOERLI, '0xe02Ed23Bb3c75753B2a628dee29A120311022898', 18, 'TN', 'Tn Token', ''),
  usdc: USDC_GOERLI,
  cake: CAKE_GOERLI,
}

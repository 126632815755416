import { FixedNumber, BigNumber } from '@ethersproject/bignumber'

export const FIXED_ZERO = FixedNumber.from(0)
export const FIXED_ONE = FixedNumber.from(1)
export const FIXED_TWO = FixedNumber.from(2)

export const BIG_TEN = BigNumber.from(10)

export const FIXED_TEN_IN_POWER_18 = FixedNumber.from(BIG_TEN.pow(18))

export const masterChefAddresses = {
  97: '0x21e2DD00AE9dB2899021DAa10030e046F1cf83C8',
  // 97: '0xF2F112180989603d51B83F1CC20b399B050665b0',
  // 97: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
  // 97: '0x2AC0D95c1054FB97B6589881e15D3D7eBAEA26b1',
  56: '0xa5f8C5Dbd5F286960b9d90548680aE5ebFf07652',
  5: '0x6CAd5632E2A6DAb282f8021a678AedF42F310cBF',
}

export const nonBSCVaultAddresses = {
  5: '0x6CAd5632E2A6DAb282f8021a678AedF42F310cBF',
}

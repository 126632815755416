import { Button, ButtonProps } from '@pancakeswap/uikit'
import { useWallet } from 'hooks/useWallet'
import Styled from 'styled-components'
// @ts-ignore
// eslint-disable-next-line import/extensions
import { useActiveHandle } from 'hooks/useEagerConnect.bmp.ts'
import Trans from './Trans'

const ConnectWalletButton = ({ children, ...props }: ButtonProps) => {
  const handleActive = useActiveHandle()
  const { onPresentConnectModal } = useWallet()

  const handleClick = () => {
    if (typeof __NEZHA_BRIDGE__ !== 'undefined') {
      handleActive()
    } else {
      onPresentConnectModal()
    }
  }
  const StyledConnectButton = Styled.button`
  width: 200px;
  height: 47px;
  left: -4px;
  color: #ADDB44;
  top: 0px;
  border 1px solid #ADDB44;;
  border-radius: 10px;
  cursor: pointer;
  font-size: 24px;  
  background: transparent;
  &:hover {
    background: linear-gradient(90.72deg, #ADDB44 -38.83%, #48A5A2 105.59%);  }
  `

  return (
    <StyledConnectButton onClick={handleClick}>Connect Wallet</StyledConnectButton>
    // <Button  onClick={handleClick} {...props}>
    //   hh
    // </Button>
  )
}

export default ConnectWalletButton

import { FooterLinkType } from '@pancakeswap/uikit'
import { ContextApi } from '@pancakeswap/localization'

export const footerLinks: (t: ContextApi['t']) => FooterLinkType[] = (t) => [
  {
    label: t('Earn'),
    items: [
      // {
      //   label: t('Earn'),
      //   href: 'https://docs.pancakeswap.finance/contact-us',
      //   isHighlighted: true,
      // },
      {
        label: t('Swap'),
        href: 'https://docs.pancakeswap.finance/brand',
      },
      {
        label: t('Stake'),
        href: 'https://medium.com/pancakeswap',
      },
      {
        label: t('Liquidity Pools'),
        href: 'https://docs.pancakeswap.finance/contact-us/telegram',
      },
      {
        label: t('Farms'),
        href: 'https://v2litepaper.pancakeswap.finance/',
      },
      // {
      //   label: '—',
      // },
      // {
      //   label: t('Online Store'),
      //   href: 'https://pancakeswap.creator-spring.com/',
      // },
    ],
  },
  {
    label: t('My Account'),
    items: [
      {
        label: t('Profile'),
        href: 'https://docs.pancakeswap.finance/contact-us/customer-support',
      },
      {
        label: t('Settings'),
        href: 'https://docs.pancakeswap.finance/help/troubleshooting',
      },
      // {
      //   label: t('Guides'),
      //   href: 'https://docs.pancakeswap.finance/get-started',
      // },
    ],
  },
  {
    label: t('Company'),
    items: [
      {
        label: 'About',
        href: 'https://github.com/pancakeswap',
      },
      {
        label: t('Career'),
        href: 'https://docs.pancakeswap.finance',
      },
      {
        label: t('Contact Us'),
        href: 'https://docs.pancakeswap.finance/code/bug-bounty',
      },
      // {
      //   label: t('Audits'),
      //   href: 'https://docs.pancakeswap.finance/help/faq#is-pancakeswap-safe-has-pancakeswap-been-audited',
      // },
      // {
      //   label: t('Careers'),
      //   href: 'https://docs.pancakeswap.finance/hiring/become-a-chef',
      // },
    ],
  },
]
